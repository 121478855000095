import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDcgFnTHrhF-_1nSirJpUkEM3TeqtQOAKQ',
  authDomain: 'gamingwithbros-8f082.firebaseapp.com',
  projectId: 'gamingwithbros-8f082',
  storageBucket: 'gamingwithbros-8f082.appspot.com',
  messagingSenderId: '1074585008815',
  appId: '1:1074585008815:web:fff8b3f74624eff604f25a',
  measurementId: 'G-E1YQ8L56HD'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

export const analytics = getAnalytics(app)
