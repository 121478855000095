import React, { useState } from 'react'
import { AppBar, Box, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material'
import { Menu } from '@mui/icons-material'

import GWB from './assets/gwb/GWB.svg'
import DiscordSM from './assets/discord/icons_white/sm.png'

const App = () => {
  const [drawOpen, setDrawOpen] = useState(false)

  const DrawerList = (
    <Box sx={{ width: 250 }} role='presentation' onClick={() => setDrawOpen(false)}>
      <List disablePadding>
        <ListItem key={'discord'}>
          <ListItemButton onClick={() => window.open('https://discord.gg/M5NSVsp')}>
            <img src={DiscordSM} alt='Discord' style={{ marginRight: 10 }} />
            <ListItemText primary={'Join Discord'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem key={'vpn'}>
          <ListItemButton>
            <ListItemText primary={'VPN Coming Soon...'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <IconButton edge='start' color='inherit' aria-label='menu' sx={{ mr: 2 }} onClick={() => setDrawOpen(true)}>
            <Menu />
          </IconButton>
          <Typography variant='h6' color='inherit' component='div'>
            GamingWithBros
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawOpen}
        onClose={() => setDrawOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#1976D2',
            color: '#FFF'
          }
        }}>
        {DrawerList}
      </Drawer>
      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Grid item>
          <img width={700} src={GWB} alt='GWB' />
        </Grid>
      </Grid>
    </>
  )
}

export default App
